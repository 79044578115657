import React, { useMemo } from "react";
import { Page1 } from "./components/Pages/Page1/Page1";
import { PageSectors } from "./components/Pages/PageSectors/PageSectors";
import { PageTerminals } from "./components/Pages/PageTerminals/PageTerminals";
import { PageTerminalDevices } from "./components/Pages/PageTerminalDevices/PageTerminalDevices";
import { PageTokens } from "./components/Pages/PageTokens/PageTokens";
import { PagePrograms } from "./components/Pages/PagePrograms/PagePrograms";

import ReactNotification from "react-notifications-component";
import "./App.sass";
import "./General.sass";
import "./Animations.css"

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { Login } from "./components/login/Login";
import { ThemeProvider } from "./components/Theme";
//Context:
import { UserProvider, useUser } from "./context/user-context";

import { Logout } from "./components/Logout/Logout";
//MQTT
import { PublisherProvider } from "./context/publish-context";
import { SubscriberProvider } from "./context/subscriber-context";
import { AppProvider } from "./context/app-context";
import { OutputsAPIProvider } from "./context/outputs-context";
import { UploaderProvider } from "./context/upload-context";
import { ProgramsProvider } from "./_services/programs.service";
import { TriggersProvider } from "./_services/triggers.service";
import { Connector } from "mqtt-react-hooks";
import { ResetPassword } from "./components/Pages/Password/ResetPassword";
import { ForgotPassword } from "./components/Pages/Password/ForgotPassword";

const basePath = process.env.REACT_APP_TECO_BASE_PATH;
export const APP_VERSION = "2.9.28";

function App() {
  return (
    <div>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <UserProvider>
        <MqttControl>
          <AppProvider>
            <UploaderProvider>
              <ProgramsProvider>
                <TriggersProvider>
                  <ThemeProvider>
                    <ReactNotification />
                    <BrowserRouter basename={basePath}>
                      <UserControl></UserControl>
                    </BrowserRouter>
                  </ThemeProvider>
                </TriggersProvider>
              </ProgramsProvider>
            </UploaderProvider>
          </AppProvider>
        </MqttControl>
      </UserProvider>
    </div>
  );
}

export const MqttControl = (props) => {
  const { user, loggedIn } = useUser();
  /*const user= {
    username: "administrador",
    password: "LaNuevaClav3!"
  }
  const loggedIn = true*/

  const options = useMemo(() => ({
    username: user?.username,
    password: user?.password,
    protocol: process.env.REACT_APP_TECO_MQTT_PROTOCOL,
    host: process.env.REACT_APP_TECO_MQTT_BROKER,
    hostname: process.env.REACT_APP_TECO_MQTT_BROKER,
    port: process.env.REACT_APP_TECO_MQTT_PORT,
    useSSL: process.env.REACT_APP_TECO_MQTT_USE_SECURE === "true",
    path: process.env.REACT_APP_TECO_MQTT_BASE_PATH,
  }), [user?.username, user?.password])

  if (user && loggedIn) {
    return (
      <Connector
        options={options}
      >
        <PublisherProvider>
          <SubscriberProvider>
            <OutputsAPIProvider>{props.children}</OutputsAPIProvider>
          </SubscriberProvider>
      </PublisherProvider>
      </Connector>
    );
  } else {
    return <div>{props.children}</div>;
  }
};

export const UserControl = (props) => {
  const { loggedIn } = useUser();

  if (!loggedIn) {
    return <Routes>
       <Route path="/password_forgot" element={<ForgotPassword />} />
       <Route path="/password_reset" element={<ResetPassword />} />
       <Route path="*" element={<Login />} />
    </Routes>
  }

  return (
    <Routes>
      <Route path="/dashboards/iot" element={<Navigate to="/zones" />} />
      <Route path="/zones" element={<Page1 />} />
      <Route
        path="/zones/:zoneId"
        caseSensitive={false}
        element={<PageSectors />}
      />
      <Route
        caseSensitive={false}
        path="/zones/:zoneId/sector/:sectorId/"
        exact
        element={<PageTerminals />}
      />
      <Route
        caseSensitive={false}
        path="/zones/:zoneId/sector/:sectorId/terminals/:terminalId"
        element={<PageTerminalDevices />}
      />
      <Route
        caseSensitive={false}
        path="/zones/:zoneId/sector/:sectorId/terminals/:terminalId/terminalDevices/:terminalDeviceId/programs"
        exact
        element={<PagePrograms />}
      />
      <Route
        path="/zones/:zoneId/sector/:sectorId/terminals/:terminalId/terminalDevices/:terminalDeviceId/programs/:programid"
        exact
        element={<PagePrograms />}
      />
      <Route
        caseSensitive={false}
        path="tokens"
        exact
        element={<PageTokens />}
      />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route index path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/main" exact element={<Page1 />} />
      {/*
          <Route path={["/test"]} element={<Test />} />
          <Route
            path={["/mqtt"]}
            element={<PageMqtt />}
          />
          <Route
            path={["/admin", "/Admin"]}
            exact
            element={<AdminPage />}
          />
          <Route path="/logout" exact element={<Logout />} />
          <Route path="/login" exact element={<Login />} />
          <Route
            path={["/Zones", "/zones", "/zonas", "/Zonas"]}
            exact
            element={<Page1 />}
          />
          <Route
            path={["/zones/:zoneId/"]}
            exact
            element={<PageSectors />}
          />
          <Route
            path={["/zones/:zoneId/sector/:sectorId/"]}
            exact
            element={<PageTerminals />}
          />
          <Route
            path={["/Tokens"]}
            exact
            element={<PageTokens />}
          />
          <Route
            path={[
              "/zones/:zoneId/sector/:sectorId/terminals/:terminalId/terminalDevices/:terminalDeviceId/programs",
            ]}
            exact
            element={<PagePrograms />}
          />

          <Route
            path={[
              "/zones/:zoneId/sector/:sectorId/terminals/:terminalId/terminalDevices/:terminalDeviceId/programs/:programid",
            ]}
            exact
            element={<PagePrograms />}
          />
          <Route
            path={[
              "/zones/:zoneId/sector/:sectorId/terminals/:terminalId",
            ]}
            element={<PageTerminalDevices />}
          />

          <Route
            path={["/taquillas"]}
            element={<PageTaquillas />}
          />

          <Route path="/main/:zonas?" element={<Login />} />
          <Route
            path="*"
            to="/main"
            element={<Page1 />}
          />*/}
    </Routes>
  );
};
export default App;
